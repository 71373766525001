import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import LocalizedLink, { LocalizedButton } from '../components/LocalizedLink'
import useTranslations from '../components/useTranslations'
import * as GS from '../layouts/styled'
import { TagCloud } from 'react-tagcloud'
import { remark } from "remark"
import remarkPresetLintMarkdownStyleGuide from "remark-preset-lint-markdown-style-guide"
import remarkHtml from "remark-html"

import GEE from '../images/gee_logo.png'
import GM from '../images/genevamiam.png'

const Index = ({ data: { allMarkdownRemark } }) => {
    // useTranslations is aware of the global context (and therefore also "locale")
    // so it'll automatically give back the right translations
    const [box_body_1, setBody] = useState("");

    useEffect(() => {
          remark()
            .use(remarkPresetLintMarkdownStyleGuide)
            .use(remarkHtml)
            .process(frontmatter.box_body_1)
            .then(file => setBody(String(file)))
      }, [])

    const indexPageData = allMarkdownRemark.edges

    const { [0]: { node: { frontmatter = {} } = {} } = {} } = indexPageData

    return (
        <div className="homepage" style={{ width: '100%' }}>
            <SEO title="Home" />

            <div
                style={{
                    marginTop: '15px',
                    padding: '20px',
                    height: '64vh',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <GS.Container
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ fontSize: '4.5em', padding: '10px' }}>
                        {frontmatter.title}
                    </div>
                    <div
                        style={{
                            fontSize: '2em',
                            padding: '10px',
                            marginTop: '5rem',
                        }}
                    >
                        {frontmatter.subtitle}
                    </div>

                    <LocalizedButton
                        activeClassName="active"
                        style={{ marginTop: '50px' }}
                        to={`/wizard`}
                    >
                        get a quote
                    </LocalizedButton>
                </GS.Container>
            </div>

            <div
                id="about"
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '15px',
                    padding: '20px',
                    color: '#fff',
                    background: '#000',
                }}
            >
                <GS.Container>
                    <h1>{frontmatter.box_title_1}</h1>
                    <div style={{ fontSize: '1em', marginTop: '15px' }}>
                        <div
                            style={{ fontSize: '1em', marginTop: '15px' }}
                            dangerouslySetInnerHTML={{
                                __html: box_body_1,
                            }}
                        />
                    </div>
                </GS.Container>
            </div>
            <div id="services" style={{ background: '#fff' }}>
                <GS.Container>
                    <h1>{frontmatter.box_title_2}</h1>
                    <TagCloud
                        disableRandomColor
                        style={{
                            maxWidth: 600,
                            textAlign: 'center',
                            margin: '0 auto',
                        }}
                        minSize={12}
                        maxSize={35}
                        tags={
                            (frontmatter.services &&
                                frontmatter.services.map &&
                                frontmatter.services.map(t => ({
                                    ...t,
                                    value: t.name,
                                }))) ||
                            []
                        }
                    />
                </GS.Container>
            </div>
            <div
                id="projects"
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '15px',
                    padding: '20px',
                    color: '#fff',
                    background: '#000',
                }}
            >
                <GS.Container>
                    <h1>Our Projects</h1>
                    <div
                        style={{
                            display: 'flex',
                            'justify-content': 'space-between',
                        }}
                    >
                        <div style={{ padding: 10 }}>
                            <a style={{'text-decoration': 'none'}} target="_blank" href="https://geneveenete.com">
                                <img
                                    style={
                                        {
                                            // padding: 20
                                        }
                                    }
                                    src={GEE}
                                    alt="Genève en été"
                                />
                                <h3>genève en été</h3>
                                <p>Geneva summer cultural guide.</p>
                            </a>
                        </div>
                        <div style={{ padding: 10 }}>
                            <a
                                style={{'text-decoration': 'none'}}
                                target="_blank"
                                href="http://instagram.com/genevamiam/"
                            >
                                <img src={GM} alt="Genève en été" />
                                <h3>genevamiam</h3>
                                <p>Best restaurants in Geneva.</p>
                            </a>
                        </div>
                    </div>
                </GS.Container>
            </div>
        </div>
    )
}

export default Index

export const query = graphql`
    query Index($locale: String!) {
        allMarkdownRemark(
            filter: {
                fields: { locale: { eq: $locale }, slug: { eq: "index" } }
            }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                        locale
                        isDefault
                    }
                    frontmatter {
                        title
                        date
                        description
                        category
                        background
                        image
                        page
                        subtitle
                        box_title_1
                        box_body_1
                        box_title_2
                        box_body_2
                        box_title_3
                        box_body_3
                        projects {
                            title
                            name
                            image
                        }
                        services {
                            name
                            title
                            count
                        }
                    }
                }
            }
        }
    }
`
